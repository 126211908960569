import {Col, Row} from "react-bootstrap"


function login() {
  return (
    <div>
      <Row>
        <Col>"hi"</Col>
        <Col>"venkat"</Col>
      </Row>
    </div>
  )
}

export default login